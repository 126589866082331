import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import {
    db,
    doc,
    runTransaction,
} from '../../Firebase';
import '../css/GPTApp.css';
import AlertDialog from "../utils/AlertDialog";
import { FaThumbsUp, FaThumbsDown, FaCopy } from 'react-icons/fa';


function Feedback({ basePath, projectName, prediction, query, feedback, setFeedback }) {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);


    // Close Alert dialog
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };
    const copyToClipboard = () => {
        // Convert query and prediction (answer) from Markdown to HTML
        const queryHtml = ReactDOMServer.renderToStaticMarkup(<ReactMarkdown>{query}</ReactMarkdown>);
        const answerHtml = ReactDOMServer.renderToStaticMarkup(<ReactMarkdown>{prediction}</ReactMarkdown>);

        // Construct the HTML content
        const content = `
        <div>
            <strong>Query:</strong>
            <div>${queryHtml}</div>
            <br/>
            <strong>Answer:</strong>
            <div>${answerHtml}</div>
        </div>
    `;

        // Create a new HTML element for copying
        const htmlElement = new Blob([content], { type: "text/html" });
        const item = new ClipboardItem({ "text/html": htmlElement });

        // Copy the HTML content to the clipboard
        navigator.clipboard.write([item]).then(() => {
            setShowErrorDialog(true);
            setErrorMessage('Answer copied to the clipboard!');
        }).catch(err => {
            setShowErrorDialog(true);
            setErrorMessage("Could not copy answer to clipboard: " + err);
        });
    };

    // Function to save feedback in Firestore
    const saveFeedback = async (basePath, projectName, queryText, feedback) => {
        try {
            await runTransaction(db, async (transaction) => {
                const contractDocRef = doc(db, basePath, "contracts", projectName.split('/')[0], "qa", projectName.split('/').join('_'));
                const contractDocSnapshot = await transaction.get(contractDocRef);
                
                // Get the current Q&A array
                const qas = contractDocSnapshot.data().qas || [];

                // Find the query index based on the query text
                const queryIndex = qas.findIndex((qa) => qa.query === queryText);
                
                // Update the feedback for the specified question and answer
                if (queryIndex !== -1) {
                    qas[queryIndex].feedback = feedback;
                    transaction.update(contractDocRef, { qas });
                }
            });
      
        } catch (error) {
            console.error("Error saving feedback:", error);
        }
    };

    const handleThumbsUp = async () => {
        // Handle the logic when thumbs up is clicked.
        //setShowErrorDialog(true);
        //setErrorMessage('Thanks for the feedback!');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(basePath, projectName, query, "thumbsUp");
        setFeedback("thumbsUp");
    };
    const handleThumbsDown = async () => {
        // Handle the logic when thumbs down is clicked.
        //setShowErrorDialog(true);
        //setErrorMessage('We appreciate your feedback. We will work on improving.');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(basePath, projectName, query, "thumbsDown");
        setFeedback("thumbsDown");
    };



    return (
        <>
            <div className="feedback-icons">
                <FaCopy onClick={copyToClipboard} title="Copy to clipboard" size={22} />

                {feedback === "thumbsUp" ? (
                    <FaThumbsUp
                        onClick={handleThumbsUp}
                        title="Good answer"
                        size={22}
                        color='var(--orange)' // Change color to indicate feedback
                    />
                ) : (
                    <FaThumbsUp
                        onClick={handleThumbsUp}
                        title="Good answer"
                        size={22}
                    />
                )
                }
                {feedback === "thumbsDown" ? (
                    <FaThumbsDown
                        onClick={handleThumbsDown}
                        title="Not a good answer"
                        size={22}
                        color='var(--orange)' // Change color to indicate feedback
                    />
                ) : (
                    <FaThumbsDown
                        onClick={handleThumbsDown}
                        title="Not a good answer"
                        size={22}
                    />
                )
                }
            </div>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />
        </>
    );
}

export default Feedback;
