import React, { useContext, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { AppContext } from '../../AppContext'
import { ResponsiveContainerQA, ResponsiveElementQA1, ResponsiveElementQA2 } from '../service/ResponsiveQA';
import {
    ref,
    storage,
    getSignedUrlCallable,
    listAll,
    db,
    setDoc,
    doc,
    getDoc,
    updateDoc,
    collection,
    runTransaction,
    serverTimestamp,
    arrayUnion,
} from '../../Firebase';
import AlertDialog from "../utils/AlertDialog";
import Feedback from "../service/Feedback";
import Gemini from "../service/Gemini";
import GeminiModal from '../utils/GeminiModal';
import '../css/GPTMenu.css';
import '../css/GPTApp.css';
import { FaFolder, FaRobot, FaUser, FaPaperPlane, FaBuffer, FaBook } from 'react-icons/fa';
import qdt1 from '../../assets/qtig/qdt1.png';

function QAContracts() {

    const { userID, projectName, syncExists, basePath } = useContext(AppContext).contextValue;

    const [query, setQuery] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [prediction, setPrediction] = useState('');

    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [geminiModalVisible, setGeminiModalVisible] = useState(false)
    const [knowledge, setKnowledge] = useState("");

    const [question, setQuestion] = useState('');

    const openGeminiModal = (knowledgeType) => {
        setKnowledge(knowledgeType);
        setGeminiModalVisible(true);
        
    };

    const closeGeminiModal = () => {
        setGeminiModalVisible(false);
        setKnowledge("");
    };

    // Warmup the API -------------------
    const warmupUrl = process.env.REACT_APP_QAQTIG1.replace('/api', '/warmup');
    
    const warmUpApi = async () => {
        try {
            const response = await fetch(warmupUrl, {
                method: 'GET',
            });
            const data = await response.text();
        } catch (error) {
            console.error("Failed to warm up the API", error);
        }
    };
    useEffect(() => {
        warmUpApi();
    }, []); 
    // Warmup end ---------------------


    // Close Alert dialog
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    // Constructing LIST OF QUESTIONS AND FETCH ANSWERS ------------------------------------

    // 1. Fetch the *Standard Questions* stored in Firebase global data --------------------
    const [standardQuestions, setStandardQuestions] = useState([]);

    useEffect(() => {
        const fetchStandardQuestions = async () => {
            const docRef = doc(db, 'globalData', 'config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setStandardQuestions(data.standardQuestions);
            } else {
                console.log("No such document!");
            }
        };

        fetchStandardQuestions().catch((error) => {
            console.log("Error fetching standard questions: ", error);
        });
    }, []);

    // 2. Fetch the *User Questions* stored in Firebase user qas  --------
    const [userQuestions, setUserQuestions] = useState([]);

    useEffect(() => {
        const fetchUserQuestions = async () => {
            if (!projectName) {
                return;
            }
            const docRef = doc(db, basePath, 'contracts', projectName.split('/')[0], "qa", projectName.split('/').join('_'));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.qas) {
                    setUserQuestions(data.qas.map(qa => qa.query));
                }
            }
        };

        fetchUserQuestions().catch(console.error);
    }, []);

    // 3. Merge *Standard* and *User* questions into *All Questions*
    const standardFlatQuestions = standardQuestions.flatMap((category) => category.questions);
    const allQuestions = [...new Set([...standardFlatQuestions, ...userQuestions])];

    // 4. Fetch the User's *Questions and Answers* stored in Firebase and save in *qas* --------
    const [qas, setQas] = useState([]);

    useEffect(() => {
        const fetchQAs = async () => {
            if (!projectName) {
                return;
            }
            const docRef = doc(db, basePath, "contracts", projectName.split('/')[0], "qa", projectName.split('/').join('_'));
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                setQas(docSnapshot.data().qas || []);
            }
        };
        fetchQAs();
    }, []); 

    // END LIST OF QUESTIONS AND FETCH ANSWERS --------------------------------------------------

    // Fetching Pricing stored in Firebase global data --------------------
    const [modelPrices, setModelPrices] = useState({});

    useEffect(() => {
        const fetchPricingData = async () => {
            const docRef = doc(db, 'globalData', 'config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const selectedModelPricing = data.pricing.find(p => p.model === "GPT3.5"); // or whatever model you need

                if (selectedModelPricing) {
                    setModelPrices(selectedModelPricing.prices);
                }
            } else {
                console.log("No such contract!");
            }
        };

        fetchPricingData().catch((error) => {
            console.log("Error fetching pricing: ", error);
        });
    }, []);


    //------------------------------
    // 1. Validation of input
    const validateInput = (projectName, query) => {
        if (!projectName || !query) {
            throw new Error("Missing filename or query.");
        }
    };

    // 2. Checking for existing answers
    const checkExistingAnswer = (qas, query) => {
        return qas.find(qa => qa.query === query);
    };

    // 3. Fetching data from your API
    const fetchDataFromAPI = async (userID, projectName, query) => {
        
        const url = process.env.REACT_APP_QAQTIG1;
        const data = { userId: basePath.replace('users/', ''), files_path: projectName, question: query };

        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const text = await response.text();
            throw new Error('Server response was not ok, please try again: ' + text);
        }

        return await response.json();
    };

    // 4. Registering Token transactions

    const updateTokens = async (result, userID) => {
        return runTransaction(db, async (transaction) => {
            const userDocRef = doc(db, basePath);
            const contractDocRef = doc(userDocRef, "contracts", projectName.split('/')[0]);
            const transactionRef = doc(collection(userDocRef, "tokenHistory"));

            // Step 4.1: Read all the required documents
            const userDocSnapshot = await transaction.get(userDocRef);
            const contractDocSnapshot = await transaction.get(contractDocRef);
            
            // Step 4.2: Calculate total token cost for this transaction
            const { emb, input, output } = modelPrices;
            const totalTokensCost = ((result.tokens.qasTokensE * emb) + (result.tokens.qasTokensI * input) + (result.tokens.qasTokensO * output)) * 0.001;

            // Step 4.3: Compute the new values for contractDoc
            // Update User Level Totals
            transaction.update(userDocRef, {
                totalAmount: (userDocSnapshot.data().totalAmount || 0) + totalTokensCost,
                tokensE_Total: (userDocSnapshot.data().tokensE_Total || 0) + result.tokens.qasTokensE,
                tokensI_Total: (userDocSnapshot.data().tokensI_Total || 0) + result.tokens.qasTokensI,
                tokensO_Total: (userDocSnapshot.data().tokensO_Total || 0) + result.tokens.qasTokensO,
            });

            // Update Contract Level Totals
            transaction.update(contractDocRef, {
                qasTokensE: (contractDocSnapshot.data().qasTokensE || 0) + result.tokens.qasTokensE,
                qasTokensI: (contractDocSnapshot.data().qasTokensI || 0) + result.tokens.qasTokensI,
                qasTokensO: (contractDocSnapshot.data().qasTokensO || 0) + result.tokens.qasTokensO,
            });

            // Store Token Usage History
            transaction.set(transactionRef, {
                timeLog: serverTimestamp(),
                tokensE: result.tokens.qasTokensE,
                tokensI: result.tokens.qasTokensI,
                tokensO: result.tokens.qasTokensO
            });
        });
    };

    // 5. Saving new Q&A
    const updateQA = async (result, query, userID, projectName) => {
        return runTransaction(db, async (transaction) => {
            const qaDocRef = doc(db, basePath, "contracts", projectName.split('/')[0], "qa", projectName.split('/').join('_'));
            const qaDocSnapshot = await transaction.get(qaDocRef);

            if (!qaDocSnapshot.exists()) {
                // Initialize the document with the first QA if it does not exist
                transaction.set(qaDocRef, {
                    qas: [{ query, prediction: result.answer }]
                });
            } else {
                // If the document exists, append the new QA to the existing array
                const existingQAs = qaDocSnapshot.data().qas || [];
                transaction.update(qaDocRef, {
                    qas: [...existingQAs, { query, prediction: result.answer }]
                });
            }
        });
    };


    //Handle the input changes ------------------------------
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    //--------------------------------

    // Handle Submit button sending the question ----------------------

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        setQuestion(query);

        try {
            validateInput(projectName, query);
            const existingAnswer = checkExistingAnswer(qas, query);

            if (existingAnswer) {
                setPrediction(existingAnswer.prediction);
                setFeedback(existingAnswer.feedback);
            } else {
                const result = await fetchDataFromAPI(userID, projectName, query);
                setPrediction(result.answer);
                setFeedback(null);
                await updateTokens(result, userID);
                await updateQA(result, query, userID, projectName);
                setQas(prevQas => [...prevQas, { query, prediction: result }]);
            }

        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("Error: " + error.message);
            setShowErrorDialog(true);
        } finally {
            setIsProcessing(false);
            setQuery('');
        }
    };


    return (
        <div className="qaPage">    
            <div className="fixFolder">
                <div className="folder">
                    <FaFolder className="folder-icon" />
                    {projectName}
                </div>
                <div className="folder" style={{ fontWeight: '300', marginRight: '30px', fontSize: '12px', textAlign: 'right' }}>
                    Q&As
                </div>
            </div>
            {/* Background image */}
            <div style={{ backgroundImage: `url(${qdt1})` }} className="fixImage"></div>

            <div style={{ padding: '0 1vw', backgroundColor: 'var(--backColor)'}}>
                <ResponsiveContainerQA>
                    <ResponsiveElementQA1>
                        <div style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.1)' } }>
                            <div className="qaTitle1">
                                <p style={{ display: 'flex', flexDirection:'row', justifyContent:'space-between' }}>
                                    <button
                                        className="icon-History"
                                        style={{ marginLeft: '0px' }}
                                        onClick={() => openGeminiModal('generalAll')}
                                        title='General Q&A History'
                                    >
                                        <FaBook className="add-icon1" />
                                    </button>
                                    General knowledge QA
                                    <button
                                        className="icon-History"
                                        onClick={() => openGeminiModal('general')} 
                                        title='Folder Q&A History'
                                    >
                                        <FaBuffer className="add-icon1" />
                                    </button>
                                </p>
                            </div>
                            <div className="qaTitle2">
                                <p>Powered by Gemini 1.5 Flash</p>
                            </div>
                            <Gemini feedback={feedback} setFeedback={setFeedback} />
                        </div>
                    </ResponsiveElementQA1>
                    <ResponsiveElementQA2>
                        <div style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.1)' }}>
                            <div className="qaTitle1">
                                <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <button
                                        className="icon-History"
                                        style={{ marginLeft: '0px' }}
                                        onClick={() => openGeminiModal('specificAll')}
                                        title='All Docs Q&A History'
                                    >
                                        <FaBook className="add-icon1" />
                                    </button>
                                    Specific knowledge QA
                                    <button
                                        className="icon-History"
                                        style={{ marginLeft: '20px' }}
                                        onClick={() => openGeminiModal('specific')}
                                        title='Folder Q&A History'
                                    //disabled={}
                                    >
                                        <FaBuffer className="add-icon1" />
                                    </button>
                                </p>
                            </div>
                            <div className="qaTitle2">
                                {!projectName ? (
                                    <p>Please <span className="project">select</span> a folder.</p>
                                ) : !syncExists ? (
                                        <p>Please <span className="project">synchronise</span> your documents.</p>
                                ) : (
                                    <p>Ask questions about your documents.</p>
                                )}
                            </div>
                            <div className="qaPageR">
                                <form onSubmit={handleSubmit} className="QuestionContainer">
                                    <div className="QuestionText">
                                        <input
                                            type="text"
                                            className="editable-div"
                                            value={query}
                                            list="questions"
                                            onChange={handleInputChange}
                                            placeholder="Select a FAQ or type your own question."
                                            name="query"
                                        />
                                        <datalist id="questions">
                                            {allQuestions.map((question, index) => (
                                                <option key={index} value={question}>
                                                    {question}
                                                </option>
                                            ))}
                                        </datalist>
                                        <div style={{ textAlign: "right" }}>
                                            <button
                                                type="submit" 
                                                className="submitButton1"
                                                disabled={!projectName || !syncExists || !query}
                                            >
                                                <FaPaperPlane size={20} />
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="AnswerText">
                                    {isProcessing ? (
                                        <div className="blink-box"></div> 
                                        ) :
                                            <>
                                                {question && (
                                                    <div className="chat-entry">
                                                        <FaUser size={15} className="chat-icon" />
                                                        <div>
                                                            <div className="label">Question:</div>
                                                            <ReactMarkdown skipHtml={true} children={question} className="chat-text" />
                                                        </div>
                                                    </div>
                                                )}
                                                {prediction && (
                                                    <>
                                                        <div className="chat-entry">
                                                            <FaRobot size={15} className="chat-icon" />
                                                            <div>
                                                                <div className="label">Answer:</div>
                                                                <ReactMarkdown skipHtml={true} children={prediction} className="chat-text" />
                                                            </div>
                                                        </div>
                                                        <Feedback userID={userID} projectName={projectName} prediction={prediction} query={question} feedback={feedback} setFeedback={setFeedback} />
                                                    </>
                                                )}

                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </ResponsiveElementQA2>
                </ResponsiveContainerQA>
             </div>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />
            <GeminiModal
                knowledge={knowledge}
                geminiModalVisible={geminiModalVisible}
                closeGeminiModal={closeGeminiModal}
                basePath={basePath}
                projectName={projectName}
            />
        </div>
    );
}

export default QAContracts; 