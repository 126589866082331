import { AppContext } from '../../AppContext'
import React, { useContext, useState, useEffect, useRef } from 'react';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import { 
    db,
    storage,
    ref,
    listAll,
    deleteObject,
    uploadString,
    doc,
    getDoc,
    setDoc,
    serverTimestamp,
    } from '../../Firebase';
import ConfirmationDialog from '../utils/ConfirmationDialog';
import EditDialog from '../utils/EditDialog';

import PdfViewerModal from '../utils/PdfViewerModal';
import PdfUploadModal from '../utils/PdfUploadModal';
import PdfSyncModal from '../utils/PdfSyncModal';

import '../css/GPTtables.css';
import '../css/GPTApp.css';

import qdt1 from '../../assets/qtig/qdt1.png';

import { FaRegCheckCircle, FaFolder, FaFolderOpen, FaRegFolder, FaSync, FaFolderPlus, FaBars, FaRegTrashAlt, FaFilePdf } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';

function PdfContracts({ userID }) {

    const { projectName, setProjectName, userStatus, basePath, directoryStructure, isLoadingProjects, fetchAvailableProjects, updateCount, setUpdateCount, verifySync, syncExists } = useContext(AppContext).contextValue;
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [newProjectName, setNewProjectName] = useState('');

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deletePDFModalVisible, setDeletePDFModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [PdfUploadModalVisible, setPdfUploadModalVisible] = useState(false);
    const [syncModalVisible, setSyncModalVisible] = useState(false);

    const [openPaths, setOpenPaths] = useState([]);

    // States for managing PDF data and visibility inside Contracts
    const [isDeleting, setIsDeleting] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [viewPdfModalVisible, setViewPdfModalVisible] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState({ pdfUrl: null, pdfName: null });
    // State to track version or update count


    const openViewPdfModal = (pdfUrl, pdfName) => {
        setSelectedPdf({ pdfUrl, pdfName });
        setViewPdfModalVisible(true);
    };

    useEffect(() => {
        if (isFirstRender) {
            // Skip on the initial render.
            setIsFirstRender(false);
            return;
        }
        fetchAvailableProjects(basePath);
    }, [updateCount]);

    // Open component viewing the projectName selected
    useEffect(() => {
        if (projectName) {
            const paths = projectName.split('/').map((_, index, arr) =>
                `${basePath}/${arr.slice(0, index).join('/')}`
            );
            setOpenPaths(paths);
        }
    }, []);
 
    const handleProjectClick = (itemName, isFolder, itemPath) => {
        if (isFolder) {
            setProjectName(itemName);
            verifySync(itemName);

            const isCurrentlyOpen = openPaths.includes(itemPath);
            let updatedOpenPaths = [];

            if (isCurrentlyOpen) {
                // Close the clicked path and its children, if open
                updatedOpenPaths = openPaths.filter(path => !path.startsWith(itemPath));
            } else {
                // Extract the path hierarchy for the clicked item
                const pathHierarchy = itemPath.split('/').slice(0, -1);
                let pathAccumulator = "";

                // Rebuild the openPaths to include only the hierarchy leading to the clicked path
                const newPathHierarchy = pathHierarchy.map((segment, index) => {
                    pathAccumulator += (index !== 0 ? '/' : '') + segment; // Avoid leading '/' for the first segment
                    return pathAccumulator;
                });

                // Include the clicked path itself
                newPathHierarchy.push(itemPath);

                // Filter current openPaths to keep only those in the new hierarchy or the root path
                updatedOpenPaths = openPaths.filter(path =>
                    newPathHierarchy.includes(path) || path === basePath
                ).concat(newPathHierarchy.filter(path => !updatedOpenPaths.includes(path)));
            }

            setOpenPaths(updatedOpenPaths);
        }
    };



    // Recursive Component to navigate in the structure
    const DirectoryItem = ({ item, openPaths, setOpenPaths }) => {
        const isFolder = item.type === 'folder';
        const isPDF = item.name.toLowerCase().endsWith('.pdf');
        const isOpen = openPaths.includes(item.path);
        const [isPDFSync, setIsPDFSync] = useState(false);

        // check is document is synchronised in BigQuery -> exists in firestore database
        useEffect(() => {
            const fetchDocument = async () => {
                if (item.type === 'file') {
                    const docRefName = `${item.path.replace(/\.pdf$/, '').split('/').slice(2).join('_')}`;
                    const docRef = doc(db, basePath, 'documents', docRefName);
                    const docSnap = await getDoc(docRef);
                    setIsPDFSync(docSnap.exists());
                }
            };
            fetchDocument();
        }, [item]);

        // Get paths excluding the userID and the last segment (item name)
        const projectPath = item.path.split('/').slice(2).join('/'); // path without userID
        const parentPath = item.path.split('/').slice(2, -1).join('/'); // path without item name

        // Check if the item is a placeholder file
        const isPlaceholder = item.name.toLowerCase().endsWith('.placeholder');
        // If the item is a placeholder, don't render it
        if (isPlaceholder) {
            return null;
        }

        return (
            <>
                <motion.tr
                    whileHover={{ backgroundColor: "rgb(246, 246, 246)" }}
                    key={item.name}
                    onClick={() => handleProjectClick(projectPath, isFolder, item.path)}
                    className={projectName === projectPath ? "td selected-project" : ""}
                >
                    {/* open-close path */}
                    <td>
                        {isFolder && ((projectPath === projectName) || isOpen ?
                            <button
                                className="icon-button"
                            >
                                {isOpen ?
                                    <FaFolderOpen className="add-icon1 o p" /> :
                                    <FaFolder className="add-icon1 o p" />
                                }
                            </button> :
                            <FaRegFolder className="add-icon1 d" />
                            ) 
                        }
                    </td>
                    {/* pdf icon moved together with the file name*/}
                    {/* Name */}
                    <motion.td
                        whileHover={{ x: 1, y: 1 }}>
                        {isFolder ?
                            (<p style={{ fontSize: '1em', margin: '0' }}>
                                <span style={{ fontSize: '0.8em' }} >{parentPath}</span>
                                {parentPath && '/'} 
                                {item.name}/
                            </p>):
                            isPDF ? (
                                <button
                                    className="pdf-button"
                                    onClick={() => openViewPdfModal(item.url, item.name)}
                                    disabled={isDeleting}
                                >
                                    <FaFilePdf className="add-iconPDF" />
                                    < span style={{ fontSize: '0.9em', marginLeft: '8px' }}>{item.name}</span>
                                </button>
                            ) : null
                        }
                    </motion.td>
                    {/* sync icon */}
                    <td>
                        {!isFolder && isPDF && (
                                !isPDFSync ?
                                <button
                                    title='Synchronise'
                                    className="icon-button"
                                    
                                    disabled={isDeleting}
                                >
                                    <FaSync className="add-ico-rotate add-icon1  d" />
                                </button> :
                                <div
                                    className="icon-button"
                                >
                                    <FaRegCheckCircle title='Synchronised'  className="add-icon1 s d" />
                                </div>
                            )
                        }
                    </td>
                    {/* Edit Menu or Delete File*/}
                    <td>
                        <>
                            {projectPath === projectName ? (
                                <button
                                    className="iconButton"
                                    title="Edit Folder"
                                    onClick={() => openModal(null,'edit')}
                                >
                                    <FaBars className="add-icon1 o" />
                                </button>
                            ) : !isFolder ? (
                                <button
                                    className="iconButton"
                                    title="Delete File"
                                        onClick={() => openModal(item.name,'deletePDF')}
                                >
                                    <FaRegTrashAlt className="add-icon1 s" />
                                </button>
                                ) : null}
                        </>
                    </td>
                </motion.tr>
                
                {/* sub tables */}
                {isFolder && isOpen && (
                    <AnimatePresence>
                        {isOpen && (
                            <motion.tr
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <td colSpan="100%">
                                    <table>
                                        <tbody>
                                            {item.children.map((child) => (
                                                <DirectoryItem key={child.path} item={child} openPaths={openPaths} setOpenPaths={setOpenPaths} />
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </motion.tr>
                        )}
                    </AnimatePresence>
                )}

            </>
        );
    };
    
    const openModal = (fileName,modalType) => {
        if (modalType === 'add') {
            setNewProjectName( projectName ?
                (`${projectName}/`):
                ('')
                );
            setAddModalVisible(true);
        } else if (modalType === 'delete') {
            setDeleteModalVisible(true);
        } else if (modalType === 'deletePDF') {
            setFileToDelete(fileName);
            setDeletePDFModalVisible(true);
        } else if (modalType === 'edit') {
            setEditModalVisible(true);
        } else if (modalType === 'addP') {
            setNewProjectName('');
            setAddModalVisible(true);
        } else if (modalType === 'uploadPdf') {
            setPdfUploadModalVisible(true);
        } else if (modalType === 'sync') {
            setSyncModalVisible(true);
        }
    };

    const closeModal = () => {
        setAddModalVisible(false);
        setDeleteModalVisible(false);
        setDeletePDFModalVisible(false);
        setEditModalVisible(false);
        setSyncModalVisible(false);
    };

    const deleteFolderContents = async (path) => {
        console.log(`Deleting contents of: ${path}`);
        const folderRef = ref(storage, path);
        const listRes = await listAll(folderRef);

        const fileDeletePromises = listRes.items.map((itemRef) => {
            console.log(`Deleting file: ${itemRef.fullPath}`);
            return deleteObject(itemRef);
        });

        const folderDeletePromises = listRes.prefixes.map((folderRef) => {
            console.log(`Deleting folder: ${folderRef.fullPath}`);
            return deleteFolderContents(folderRef.fullPath);
        });

        /*
        // Clear data in Firestore

        const docRef = doc(db, "users", userID, "contracts", projectName);
        await updateDoc(docRef, {
            qas: [],
            summary: []
        });
        */

        // This will return when all the files and subdirectories have been deleted.
        return Promise.all([...fileDeletePromises, ...folderDeletePromises]);
    }

    const deleteFolder = async () => {
        if (!projectName) return;
        // Delete the project's directory from Firebase Storage
        await deleteFolderContents(`${basePath}/${projectName}`);
        // Reset the current project name
        setProjectName("");
        // Refresh the list of files/folders
        setUpdateCount(count => count + 1); // Refresh the list of projects
        // Close the delete modal
        setDeleteModalVisible(false);

    };
    const uploadFile = () => {
        // Refresh the list of files/folders
        setUpdateCount(count => count + 1); // Refresh the list of projects
        // Close the upload modal
        setPdfUploadModalVisible(false);

    };
    const createProject = async () => {
        if (!newProjectName) {
            // Handle error when project name is not provided
            return;
        }
        // Create a placeholder in Storage to signify the project's existence
        const projectPath = `${basePath}/${newProjectName}/.placeholder`;
        const projectRef = ref(storage, projectPath);

        try {
            // Attempt to upload the placeholder string to Storage
            await uploadString(projectRef, '');

            // Attempt to retrieve the document
            const contractDocRef = doc(db, "users", userID, "contracts", newProjectName.split('/')[0]);
            const docSnapshot = await getDoc(contractDocRef);

            // Check if the document exists
            if (!docSnapshot.exists()) {
                // Document does not exist, create it
                await setDoc(contractDocRef, {
                    contractName: newProjectName.split('/')[0],
                    qas: [],
                    docTokensE: 0,
                    qasTokensE: 0,
                    qasTokensI: 0,
                    qasTokensO: 0,
                    timeLog: serverTimestamp(),
                });
            }
            // Update state and UI after successful creation
            setProjectName(newProjectName);
            setUpdateCount(count => count + 1); // Refresh the list of projects
            const paths = newProjectName.split('/').map((_, index, arr) =>
                `users/${userID}/${arr.slice(0, index).join('/')}`
            );
            setOpenPaths(paths);
            setNewProjectName(''); // Clear the new project name input
            setAddModalVisible(false); // Close the modal
        } catch (error) {
            console.error("Error creating project:", error);
        }
    };

    const handleDeletePdf = async () => {
        setIsDeleting(true);
        try {
            const fileRef = ref(storage, `${basePath}/${projectName}/${fileToDelete}`);
            await deleteObject(fileRef);
            // Indicate a successful deletion and need to re-fetch
            setUpdateCount(count => count + 1);
        } catch (error) {
            console.error("Error deleting file:", error);
        } finally {
            setIsDeleting(false);
            setDeletePDFModalVisible(false);
        }
    };

    return (
        <div>
            <div className="qaPage">
                <div className="fixFolder">
                    <div className="folder">
                        <FaFolder className="folder-icon" />
                        {projectName}
                    </div>
                    <div className="folder" style={{ fontWeight: '300', marginRight: '30px', fontSize: '12px', textAlign: 'right' } }>
                        Document Explorer
                    </div>

                </div>
                {/* Background image */} 
                <div style={{backgroundImage: `url(${qdt1})`}} className="fixImage"></div>

                
                {/* Document Table */} 
                <div className="ContractTable" >
                    <div className="PdocTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <button
                                            title="Add a new Project"
                                            className="icon-History"
                                            onClick={() => openModal(null, 'addP')} >
                                            <FaFolderPlus className="add-icon" />
                                        </button>
                                    </th>
                                    <th>Folder name</th>
                                    <th>Sync</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>

                            {/* blinking 5 empty rows while loading */ }
                            {isLoadingProjects ? (
                                <tbody>
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <tr key={index}>
                                            <td colSpan="100%" className="blink-box-row1" />
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                
                                    <tbody>

                                        {directoryStructure.map((item) => (
                                                <DirectoryItem key={item.path} item={item} openPaths={openPaths} setOpenPaths={setOpenPaths} />
                                        ))}

                                </tbody>
                                
                            )}
                        </table>
                        
                        {/* Dialog Edit Folder */}   
                        <EditDialog
                            show={editModalVisible}
                            title="Edit Folder"
                            message={
                                <>
                                    Add a folder, a pdf or delete current folder.
                                </>
                            }
                            onCancel={closeModal}
                            onDelete={() => {
                                setEditModalVisible(false);
                                openModal(null, 'delete');
                            }}
                            onAddFolder={() => {
                                setEditModalVisible(false);
                                openModal(null, 'add');
                            }}
                            onAddPdf={() => {
                                setEditModalVisible(false);
                                openModal(null, 'uploadPdf');
                            }}
                            onSync={() => {
                                setEditModalVisible(false);
                                openModal(null, 'sync');
                            }}
                            cancelLabel= "Cancel"
                        />
                        {/* Dialog Delete a Folder */} 
                        <ConfirmationDialog
                            show={deleteModalVisible}
                            title="Delete Folder"
                            message={
                                <>
                                    Are you sure you want to delete <strong>{projectName}</strong>?
                                    This will delete all documents inside this folder.
                                </>
                            }
                            onConfirm={() => deleteFolder()}
                            onCancel={closeModal}
                            confirmLabel="Delete"
                            cancelLabel="Cancel"
                        />
                        {/* Dialog Add a Folder */}
                        <ConfirmationDialog
                            show={addModalVisible}
                            title="Add a new folder"
                            message={
                                <input
                                    className="input-modal"
                                    type="text"
                                    placeholder="Enter new project folder"
                                    value={newProjectName}
                                    onChange={(e) => setNewProjectName(e.target.value)}
                                />
                            }
                            onConfirm={() => {
                                createProject();
                            }}
                            onCancel={closeModal}
                            confirmLabel="Add"
                            cancelLabel="Cancel"
                        />
                        {/* Dialog Delete a PDF */}
                        <ConfirmationDialog
                            show={deletePDFModalVisible}
                            title="Delete File"
                            message={
                                <>
                                    {`Please confirm the removal of "${fileToDelete}".`}                                   
                                </>
                            }
                            onConfirm={handleDeletePdf}
                            onCancel={closeModal}
                            confirmLabel="Delete"
                            cancelLabel="Cancel"
                        />
                        {/* Dialog Synchronise Folder */}
                        <ConfirmationDialog
                            show={syncModalVisible}
                            title="Synchronise folder"
                            message={
                                <>
                                    Do you want to synchronise all files in <strong>{projectName}</strong> folder now?
                                    
                                </>
                            }
                            onConfirm={() => { setSyncModalVisible(true) }}
                            onCancel={closeModal}
                            confirmLabel="Sync"
                            cancelLabel="Cancel"
                        />
                    </div>
                </div>
            </div>
            {/* PDF Viwer */}
            <PdfViewerModal
                viewPdfModalVisible={viewPdfModalVisible}
                closePdfModal={() => setViewPdfModalVisible(false)}
                selectedPdf={selectedPdf}
            />
            {/* PDF Uploader */}
            <PdfUploadModal
                PdfUploadModalVisible={PdfUploadModalVisible}
                onConfirm={() => uploadFile()}
                onCancel={() => setPdfUploadModalVisible(false)}
                userID={userID}
                projectName={projectName}
                userStatus={userStatus}
            />
            <PdfSyncModal
                PdfSyncModalVisible={syncModalVisible}
                closePdfSyncModal={() => setSyncModalVisible(false)}
                userID={userID}
                projectName={projectName}
                userStatus={userStatus}
            />
            
        </div>
    )
}


export default PdfContracts;
