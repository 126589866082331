import React, { useState, useRef, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { FaPaperPlane, FaUser, FaRobot } from 'react-icons/fa'; // Assuming FaRobot for system icon
import Feedback from "./Feedback";
import { AppContext } from '../../AppContext'
import {
    db,
    doc,
    collection,
    setDoc,
    serverTimestamp,
} from '../../Firebase';
function Gemini({feedback, setFeedback }) {
    const { basePath, projectName } = useContext(AppContext).contextValue;
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [content, setContent] = useState(''); // Track the content of the div
    const [isLoading, setIsLoading] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);

    const handleInput = () => {
        // Update the state based on the div's content
        const text = inputRef.current.innerText.trim();
        setContent(text);
    };
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        // Also update content to ensure the placeholder is correctly shown/hidden when the div is empty
        const text = inputRef.current.innerText.trim();
        setContent(text);
    };

    // Saving Gemini Q&A
    const updateGQA = async (message, answerText, basePath, projectName) => {
        const llmColRef = collection(db, basePath, "llm");
        const newDocRef = doc(llmColRef);

        const doc_data = {
            'project': projectName.split('/').join('_'),
            'createdAt': serverTimestamp(),
            'question': message,
            'answer': answerText,
        };
        await setDoc(newDocRef, doc_data).catch(error => console.error("Error writing document: ", error));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const message = inputRef.current.innerText.trim();

        if (!message) {
            console.error('Message is empty.');
            return;
        }
        setIsLoading(true);
        setQuestion(message); // Update last question

        const url = process.env.REACT_APP_GEMINI_URL;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text: message }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const answerText = data.candidates[0].content.parts[0].text;
            setAnswer(answerText); // Update last answer

            await updateGQA(message, answerText, basePath, projectName);
            
        } catch (error) {
            console.error('Error:', error);
            setAnswer('Failed to fetch answer. Please try again.');
        } finally {
            setIsLoading(false);
            inputRef.current.innerText = ''; // Clear input
            setContent('');
        }
    };

    return (
        <div className="qaPageR">
            <form onSubmit={handleSubmit} className="QuestionContainer">
                <div className="QuestionText">
                    <div
                        ref={inputRef}
                        contentEditable
                        onInput={handleInput} 
                        className={`editable-div ${!content ? 'empty' : ''}`}
                        data-placeholder="Your question..."
                        onBlur={handleBlur} 
                        onFocus={handleFocus}
                    >     
                    </div>
                    <div style={{textAlign:"right"} }>
                        <button type="submit" className="submitButton1" disabled={isLoading || !content}>
                            <FaPaperPlane size={20} />
                            </button>
                    </div>
                </div>
            </form>

            <div className="AnswerText">
                {isLoading ? (
                    <div className="blink-box"></div> // Your custom loading indicator
                ) : (
                    <>
                        {question && (
                            <div className="chat-entry">
                                <FaUser size={15} className="chat-icon" />
                                <div>
                                    <div className="label">Question:</div>
                                    <ReactMarkdown skipHtml={true} children={question} className="chat-text" />
                                </div>
                            </div>
                        )}
                        {answer && (
                            <>
                                <div className="chat-entry">
                                    <FaRobot size={15} className="chat-icon" />
                                    <div>
                                        <div className="label">Answer:</div>
                                        <ReactMarkdown skipHtml={true} children={answer} className="chat-text" />
                                    </div>
                                </div>
                                    <Feedback basePath={basePath} projectName={projectName} prediction={answer} query={question} feedback={feedback} setFeedback={setFeedback} />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );



}

export default Gemini;