import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PdfUpload from '../service/pdfUpload2';
import "../css/pdf.css";
import "../css/Dialog.css";
import "../css/GPTApp.css";


function PdfUploadModal({ PdfUploadModalVisible, onConfirm, onCancel, userID, projectName }) {

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleFilesUploaded = (files) => {
        setUploadedFiles(files);
    };

    return (
        <Modal show={PdfUploadModalVisible}  scrollable={true} className="modalPDF" backdrop="static" dialogClassName="modalPDF-dialog">

            <Modal.Header className="modalHeader">
                <Modal.Title className="modalTitle">
                    <span >File Uploader </span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalBody" >
                <div className="upContainer">
                    <div className="pdfUploadTitle">
                        {projectName
                            ? <p>Upload PDF file(s) in <span className="project">{projectName.toUpperCase()}</span> folder</p>
                            : "Please select a Contract"}
                    </div>
                    <PdfUpload
                        userID={userID}
                        projectName={projectName}
                        onFilesUploaded={handleFilesUploaded}
                        onStartLoading={() => setLoadingFiles(true)}
                        onFinishLoading={() => setLoadingFiles(false)}
                        maxFiles={1000}
                    />
                    <div className="loadFileContainer">
                        {uploadedFiles.length > 0 && (
                            <div>
                                <p>{uploadedFiles.length} file(s) successfully uploaded!</p>
                                
                            </div>
                        )}
                        {loadingFiles && (
                            <div>
                                <p className="pdfList">Loading PDF files...</p>
                                <div className="loading-spinner-container1">
                                    <div className="loading-spinner1"></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalFooter">
                <Button
                    className="cancelButton"
                    variant="dark"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button className="confirmButtonPDF"
                    variant="dark"
                    onClick={() => {
                        onConfirm(); // Call the function
                        setUploadedFiles([]); // Reset the state
                    }}
                >
                    Done
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
export default PdfUploadModal;
