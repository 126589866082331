import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext'
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';

import {
    db,
    setDoc,
    doc,
    getDoc,
    runTransaction,
} from '../../Firebase';

import { motion } from 'framer-motion';

import AlertDialog from "../utils/AlertDialog";
import ConfirmationDialog from "../utils/ConfirmationDialog";

import SummaryTable from './SummaryTable';
import SummaryQModal from '../utils/SummaryQModal';
import SummaryDocModal from '../utils/SummaryDocModal';
import fetchPDFs from '../utils/FetchPDFs';
import PdfViewerModal from '../utils/PdfViewerModal';

import { useGeminiSummary } from '../service/useGeminiSummary';

import { FaRegCheckCircle, FaFilePdf, FaFolder, FaThumbsUp, FaThumbsDown, FaCopy, FaRegListAlt } from 'react-icons/fa';

import '../css/GPTMenu.css';
import '../css/GPTApp.css';
import '../css/Analysis.css';

import qdt1 from '../../assets/qtig/qdt1.png';


function SummaryContracts() {
    const { userID, projectName, syncExists, basePath } = useContext(AppContext).contextValue;
    const { docSummary, isLoading, generateSummary } = useGeminiSummary();

    const [feedback, setFeedback] = useState(null);
    const [SummaryModalVisible, setSummaryModalVisible] = useState(false);
    
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const columns = ['Topic', 'Content/Clauses'];
    const [rowData, setRowData] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [summary, setSummary] = useState(false); // set if a summary already exists
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    //DOCUMENTS TAB Summary Modal
    const [showDocSumDialog, setShowDocSumDialog] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState({ pdfUrl: "", pdfName: "", pdfSummary:"" });
    const [SummaryDocModalVisible, setSummaryDocModalVisible] = useState(false);
    const [viewPdfModalVisible, setViewPdfModalVisible] = useState(false);
    const closeDocSummaryModal = () => {
        setSummaryDocModalVisible(false)
    };
    const [selectedPdfBQuery, setSelectedPdfBQuery] = useState('');

    const selectPDF = (pdfUrl, pdfName, pdfSummary) => {

        setSelectedPdf(prevState => ({
            ...prevState,     
            pdfUrl: pdfUrl,
            pdfName: pdfName,   
            pdfSummary: pdfSummary  
        }));

        const pdfPath = (`${projectName}/${pdfName}`)
        setSelectedPdfBQuery(pdfPath);

        if (!pdfSummary) {
            setShowDocSumDialog(true);
        } else {
            setSummaryDocModalVisible(true);
        }
    };
    //Handle confirmation for DocSummary
    const docSummaryConfirm = async () => {
        setShowDocSumDialog(false);
        setSummaryDocModalVisible(true);

        const generatedSummary = await generateSummary(selectedPdf.pdfName);  // Get the generated summary

        if (generatedSummary) {
            setSelectedPdf(prevState => ({
                ...prevState,  // Spread the previous state
                pdfSummary: generatedSummary  // Update pdfSummary with the returned value
            }));
        }

        setSummaryDocModalVisible(true);
    };
    //Open pdf viewer Modal in DocSummary
    const openViewPdfModal = (pdfUrl, pdfName, pdfSummary) => {

        setSelectedPdf(prevState => ({
            ...prevState,
            pdfUrl: pdfUrl,
            pdfName: pdfName,
            pdfSummary: pdfSummary
        }));

        setViewPdfModalVisible(true);
    };

    //TAB-CONTAINER STATE
    // State to keep track of the current active tab
    const [activeTab, setActiveTab] = useState('contract');

    // Close Alert dialog
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    // Function to change the active tab
    const changeTab = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('lastSelectedTab', tab);
    };

    //Fetch pdfs in the current path
    const pathPDF = `${basePath}/${projectName}`
    const [pdfList, setPdfList] = useState([]);

    useEffect(() => {
        // Retrieve the last selected tab from local storage
        const lastTab = localStorage.getItem('lastSelectedTab') || 'contract';
        setActiveTab(lastTab);
        if (projectName) {
            
            const fetchPdfs = async () => {
                const pdfs = await fetchPDFs(pathPDF, basePath);
                setPdfList(pdfs);
            };

            fetchPdfs().catch(console.error);
        }
    }, [selectedPdf.pdfSummary]);

    //LETTERS TAB - start -----------------------------
    const [showLetterSumDialog, setShowLetterSumDialog] = useState(false);
    const [selectedPDFs, setSelectedPDFs] = useState([]);

    const togglePDFSelection = (id) => {
        setSelectedPDFs(current =>
            current.includes(id)
                ? current.filter(questionId => questionId !== id)
                : [...current, id]
        );
    };

    const handleSelectAllToggle = () => {
        if (selectedPDFs.length < pdfList.length) {
            // Not all PDFs are selected - select all
            setSelectedPDFs(pdfList.map(letter => letter.name));
        } else {
            // All questions are selected - clear selection
            setSelectedPDFs([]);
        }
    };
    //LETTERS TAB - end -------------------------------------

    //API CALL start ______________________________________________________
    const fetchData = async (userID, projectName, question) => {
        const url = process.env.REACT_APP_QAQTIG1;
        const data = { userId: userID, files_path: projectName, question: question };
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error("API error: " + await response.text());
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("API error:", error);
            throw error;
        }
    };
    //API CALL end ___________________________________________________

    // Handle Modal Submit ____________________________
    const handleModalSubmit = async (selectedQuestionDetails) => {
        setIsSubmit(true);

        // Create a copy of the current rowData to work with
        let updatedData = [...rowData];  // Use `let` to allow mutations

        // Loop through each selected question
        for (const question of selectedQuestionDetails) {
            // Check if the question's answer is already in `rowData`
            const existing = updatedData.find(row => row.id === question.id);
            if (!existing) {
                // If not, fetch the data and update Firestore
                try {
                    const result = await fetchData(userID, projectName, question.question);
                    const newData = { ...question, contentCl: result.answer };

                    // Add the new data to the updatedData array
                    updatedData = [...updatedData, newData];  // Reassign to `updatedData`

                    // Update state with new data
                    setRowData(updatedData);

                    // Save to Firestore
                    await saveToFirestore(updatedData, basePath);
                } catch (error) {
                    console.error('Error processing question:', error);
                    // Handle error (e.g., show error message)
                }
            }
        }

        setSummaryModalVisible(false); // Close the modal after processing
    };



    const closeSummaryModal = () => {
        setSummaryModalVisible(false)
    };

    //Fetch data saved in Firestore _ when the component mounts____
    useEffect(() => {
        const fetchFromFirestore = async () => {
            if (!projectName) {
                return;
            }
            const docRef = doc(db, basePath, "contracts", projectName.split('/')[0], "analysis", projectName.split('/').join('_'));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();

                // Check if the summary field exists and is not an empty array
                if (data.summary && data.summary.length > 0) {
                    setRowData(data.summary);
                    setSummary(true);
                    setFeedback(data.summaryFeedback);
                }
            }
        };

        fetchFromFirestore().catch(console.error);
    }, []);
    //Summary data end __________________________________________


    const saveToFirestore = async (updatedData, basePath) => {
        const docRef = doc(db, "users", basePath, projectName.split('/')[0], "analysis", projectName.split('/').join('_'));
        await setDoc(docRef, { summary: updatedData }, { merge: true });
    };


    // Summary ICONS feedback and save___________________________________
    // Function to save to clipboard
    const copyTableToClipboard = () => {
        // Sort rowData by the 'id' field to ensure correct order
        const sortedRowData = [...rowData].sort((a, b) => a.id - b.id);

        // Construct an HTML table with the sorted data
        let tableContent = `
    <table border="1" cellpadding="5" cellspacing="0">
        <thead>
            <tr>
                ${columns.map(col => `<th>${col}</th>`).join("")} <!-- Header row -->
            </tr>
        </thead>
        <tbody>
            ${sortedRowData.map(row => `
                <tr>
                    <td>${row.topic}</td>
                    <td>${ReactDOMServer.renderToStaticMarkup(<ReactMarkdown>{row.contentCl}</ReactMarkdown>)}</td> <!-- Convert Markdown to HTML -->
                </tr>`).join("")} <!-- Body rows -->
        </tbody>
    </table>
    `;

        // Create a new HTML element for copying
        const htmlElement = new Blob([tableContent], { type: "text/html" });
        const item = new ClipboardItem({ "text/html": htmlElement });

        // Copy the HTML content to the clipboard
        navigator.clipboard.write([item]).then(() => {
            setErrorMessage("Summary table successfully copied to clipboard!");
            setShowErrorDialog(true);
        }).catch(err => {
            setErrorMessage("Could not copy table to clipboard: " + err);
            setShowErrorDialog(true);
        });
    };



    // Function to save feedback in Firestore
    const saveFeedback = async (basePath, projectName, feedback) => {
        try {
            await runTransaction(db, async (transaction) => {
                const contractDocRef = doc(db, basePath, "contracts", projectName.split('/')[0], "analysis", projectName.split('/').join('_'));
                const contractDocSnapshot = await transaction.get(contractDocRef);

                // Get the current data
                const contractData = contractDocSnapshot.data() || {};

                contractData.summaryFeedback = feedback;

                transaction.update(contractDocRef, contractData);
            });

            console.log(`Feedback (${feedback}) saved successfully.`);
        } catch (error) {
            console.error("Error saving feedback:", error);
        }
    };
    //Feedback
    const handleThumbsUp = async () => {
        // Handle the logic when thumbs up is clicked.
        setShowErrorDialog(true);
        setErrorMessage('Thanks for the feedback!');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(basePath, projectName, "thumbsUp");
        setFeedback("thumbsUp");
    };
    const handleThumbsDown = async () => {
        // Handle the logic when thumbs down is clicked.
        setShowErrorDialog(true);
        setErrorMessage('We appreciate your feedback. We will work on improving.');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(basePath, projectName, "thumbsDown");
        setFeedback("thumbsDown");
    };

    function AnalysisContent({ tab }) {
        return (
            <div className="STitleContainer">
                <div className="STitle1">Contract Analysis</div>
                <div className="STitle2">
                    {!summary ?
                        <p>(remember the folder must contain <span className="project">{tab}</span> documents)</p>
                        : <p></p>
                    }
                </div>
                <div className="STitle3">
                    {!projectName ? (
                        <p>Please select a folder containing the {tab}.</p>
                    ) : summary ? (
                        <p>Your {tab} analysis is already processed.</p>
                    ) : syncExists ? (
                        <p>Perform a quick analysis of the {tab}.</p>
                    ) : (
                        <p>Please ensure the <span className="project">{projectName}</span> folder has synchronised documents.</p>
                    )}
                </div>
                <div className="STitle4">
                    <button
                        className="submitButton s"
                        onClick={() => setSummaryModalVisible(true)}
                        disabled={!syncExists}
                    >
                        {summary ? 'View' : 'Submit'}
                    </button>
                </div>
            </div>
        )

    }

    return (
        <div className="qaPage">
            <div className="fixFolder">
                <div className="folder">
                    <FaFolder className="folder-icon" />
                    {projectName}
                </div>
                <div className="folder" style={{ fontWeight: '300', marginRight: '30px', fontSize: '12px', textAlign: 'right' }}>
                    Docs Analysis
                </div>
            </div>
            {/* Background image */}
            <div style={{ backgroundImage: `url(${qdt1})` }} className="fixImage"></div>

            <div className="AnalysisPage">
                <div className="AnalysisContainer">
                    <ul className="tab-headers">
                        <li className={activeTab === 'contract' ? 'active' : ''} onClick={() => changeTab('contract')}>contract</li>
                        <li className={activeTab === 'documents' ? 'active' : ''} onClick={() => changeTab('documents')}>documents</li>
                        <li className={activeTab === 'letters' ? 'active' : ''} onClick={() => changeTab('letters')}>letters</li>
                        <li className={activeTab === 'emails' ? 'active' : ''} onClick={() => changeTab('emails')}>emails</li>

                    </ul>
                    <div className="AnalysisContent">
                        {activeTab === 'contract' &&
                            <>
                                <AnalysisContent tab="contract" />
                                {isSubmit ? (
                                    <div className="qaPageR">
                                        <div className="STable">
                                            <SummaryTable
                                                columns={columns}
                                                rowData={rowData}
                                                totalRows={selectedQuestions.length}
                                                isDataComplete={rowData.length === selectedQuestions.length}
                                                selectedQuestions={selectedQuestions}
                                            />
                                            <div className="feedback-icons">
                                                <FaCopy onClick={copyTableToClipboard} title="Copy to clipboard" size={22} />

                                                {feedback === "thumbsUp" ? (
                                                    <FaThumbsUp
                                                        onClick={handleThumbsUp}
                                                        title="Good answer"
                                                        size={22}
                                                        color='var(--orange)' // Change color to indicate feedback
                                                    />
                                                ) : (
                                                    <FaThumbsUp
                                                        onClick={handleThumbsUp}
                                                        title="Good answer"
                                                        size={22}
                                                    />
                                                )
                                                }
                                                {feedback === "thumbsDown" ? (
                                                    <FaThumbsDown
                                                        onClick={handleThumbsDown}
                                                        title="Not a good answer"
                                                        size={22}
                                                        color='var(--orange)' // Change color to indicate feedback
                                                    />
                                                ) : (
                                                    <FaThumbsDown
                                                        onClick={handleThumbsDown}
                                                        title="Not a good answer"
                                                        size={22}
                                                    />
                                                )
                                                }
                                            </div>

                                        </div>

                                    </div>
                                ) :
                                    (<div></div>)
                                }
                            </>}
                        {activeTab === 'emails' &&
                            <>
                                <div className="STitleContainer">
                                <div className="STitle1"><br />Coming Soon!</div>
                                    <div className="STitle3"></div>
                                </div>

                            </>}
                        {activeTab === 'letters' &&
                            <div style={{ padding: "0 20px 30px 20px" }}>
                                <div className="STitleContainer">
                                    <div className="STitle1">Correspondence Analysis</div>
                                    
                                    <div className="STitle2">
                                        <button
                                            className="submitButton s"
                                            onClick={() => setShowLetterSumDialog(true)}
                                            disabled={selectedPDFs.length < 2}
                                        >
                                            Submit
                                        </button>

                                    </div>
                                    <div className="STitle4"></div>
                                </div>
                                {pdfList.length > 0 && (
                                    <div className="PdocTablePDF">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>N.</th>
                                                    <th></th>
                                                    <th>Letters</th>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedPDFs.length === pdfList.length} 
                                                            onChange={handleSelectAllToggle}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pdfList.map((pdf, index) => (
                                                    <motion.tr
                                                        key={index}

                                                    >
                                                        <td>{index + 1}</td>
                                                        <motion.td whileHover={{ x: 1, y: 1 }}>
                                                            <button
                                                                className="pdf-button"
                                                                onClick={() => openViewPdfModal(pdf.url, pdf.name, pdf.summary)}
                                                            >
                                                                <FaFilePdf className="add-iconPDF" />
                                                            </button>
                                                        </motion.td>
                                                        <motion.td whileHover={{ x: 1, y: 1 }}>
                                                            <button
                                                                className="pdf-button"
                                                                onClick={() => selectPDF(pdf.url, pdf.name, pdf.summary)}
                                                            >
                                                                < span style={{ fontSize: '0.9em', marginLeft: '8px' }}>{pdf.name}</span>
                                                            </button>
                                                        </motion.td>
                                                        <motion.td whileHover={{ x: 1, y: 1 }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedPDFs.includes(pdf.name)}
                                                                onChange={() => togglePDFSelection(pdf.name)}
                                                            />
                                                        </motion.td>
                                                    </motion.tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        }
                        {activeTab === 'documents' &&
                            <div style={{padding: "0 20px 30px 20px"}}>
                                <div className="STitleContainer">
                                    <div className="STitle1">Perform a quick summary</div>
                                    <div className="STitle2">
                                        <p>(select a document)</p>
                                        

                                    </div>
                                </div>
                                {pdfList.length > 0 && (
                                    <div className="PdocTablePDF">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>PDFs</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pdfList.map((pdf, index) => (
                                                    <motion.tr
                                                        key={index}
                                                        
                                                    >
                                                        <td>{index + 1}</td>
                                                        <motion.td whileHover={{ x: 1, y: 1 }}>
                                                            <button
                                                                className="pdf-button"
                                                                onClick={() => openViewPdfModal(pdf.url, pdf.name, pdf.summary )}
                                                            >
                                                                <FaFilePdf className="add-iconPDF" />
                                                            </button>
                                                        </motion.td>
                                                        <motion.td whileHover={{ x: 1, y: 1 }}>
                                                            <button
                                                                className="pdf-button"
                                                                onClick={() => selectPDF(pdf.url, pdf.name, pdf.summary) }
                                                            >
                                                                < span style={{ fontSize: '0.9em', marginLeft: '8px' }}>{pdf.name}</span>
                                                            </button>
                                                        </motion.td>
                                                        <motion.td whileHover={{ x: 1, y: 1 }}>
                                                            {pdf.summary && (
                                                                <button
                                                                    className="pdf-button"
                                                                    onClick={() => selectPDF(pdf.url, pdf.name, pdf.summary)}
                                                                >
                                                                    <FaRegListAlt
                                                                        title="Summary Available"
                                                                        className="add-iconPDF"
                                                                    />
                                                                </button>
                                                                
                                                            )}
                                                        </motion.td>
                                                    </motion.tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                </div>

            </div>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />
            <ConfirmationDialog
                show={showDocSumDialog}
                title="Document summary"
                message={(
                    <div>
                        <p>Perform a quick summary of:</p>
                        <p style={{ fontWeight: "600", marginLeft: "20px"}}>{selectedPdf.pdfName}</p>
                    </div>
                )}
                onConfirm={docSummaryConfirm}
                confirmLabel="OK"
                onCancel={() => setShowDocSumDialog(false)}
                cancelLabel="Cancel"
            />
            <ConfirmationDialog
                show={showLetterSumDialog}
                title="Letters Analysis"
                message={(
                    <div>
                        <p>Perform a quick summary of the <b>{selectedPDFs.length}</b> selected letters.</p>
                    </div>
                )}
                onConfirm={() => setShowLetterSumDialog(false)}
                confirmLabel="OK"
                onCancel={() => setShowLetterSumDialog(false)}
                cancelLabel="Cancel"
            />
            {/* Contract analysis Modal */}
            <SummaryQModal
                SummaryModalVisible={SummaryModalVisible}
                setSummaryModalVisible={setSummaryModalVisible}
                closeSummaryModal={closeSummaryModal}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                onSubmit={handleModalSubmit}
                syncExists={syncExists}
                rowData={rowData}
                basePath={basePath}  
                projectName={projectName} 
                setRowData={setRowData}
            />
            {/* Document analysis (doc summary) Modal */}
            <SummaryDocModal
                SummaryDocModalVisible={SummaryDocModalVisible}
                setSummaryDocModalVisible={setSummaryDocModalVisible}
                closeDocSummaryModal={closeDocSummaryModal}
                summary={selectedPdf.pdfSummary}
                pdf={selectedPdf.pdfName}
                isLoading={isLoading}
            />
            {/* PDF Viwer */}
            <PdfViewerModal
                viewPdfModalVisible={viewPdfModalVisible}
                closePdfModal={() => setViewPdfModalVisible(false)}
                selectedPdf={selectedPdf}
            />


        </div>
    );
}

export default SummaryContracts; 