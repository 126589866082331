import React, { useState, useEffect } from 'react';
import {
    db,
    doc,
    getDoc,
    updateDoc,
    runTransaction,
} from '../../Firebase';
import { Modal, Button } from 'react-bootstrap';
import "../css/Dialog.css";
import "../css/GPTtables.css";
import "../css/radio.css";
import AlertDialog from "./AlertDialog";
import ConfirmationDialog from './ConfirmationDialog';
import { FaRegCheckCircle, FaRegTrashAlt } from 'react-icons/fa';

function SummaryQModal({ SummaryModalVisible, setSummaryModalVisible, closeSummaryModal, selectedQuestions, setSelectedQuestions, onSubmit, syncExists, rowData, basePath, projectName, setRowData }) {

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const [deleteAnswerModalVisible, setDeleteAnswerModalVisible] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);


    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    const toggleQuestionSelection = (id) => {
        setSelectedQuestions(current =>
            current.includes(id)
                ? current.filter(questionId => questionId !== id)
                : [...current, id]
        );
    };

    // Fetch the *Summary Questions* stored in Firebase global data
    const [summaryQuestions, setSummaryQuestions] = useState([]);

    useEffect(() => {
        const fetchSummaryQuestions = async () => {
            try {
                const docRef = doc(db, 'globalData', 'config');
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setSummaryQuestions(data.summaryQuestions);
                    setSelectedQuestions(data.summaryQuestions.map(question => question.id));
                } else {
                    console.log("No global data!");
                }
            } catch (error) {
                console.log("Error fetching summary questions: ", error);
            }
        };

        fetchSummaryQuestions();

    }, []);

    const questions = summaryQuestions;

    const getSelectedQuestionDetails = (summaryQuestions, selectedQuestions) => {
        return summaryQuestions.filter(question => selectedQuestions.includes(question.id));
    };

    const handleSubmit = () => {
        const selectedQuestionDetails = getSelectedQuestionDetails(summaryQuestions, selectedQuestions);
        onSubmit(selectedQuestionDetails); // Call the parent's function with the current selection
        setSummaryModalVisible(false);
    };

    const handleSelectAllToggle = () => {
        if (selectedQuestions.length < questions.length) {
            // Not all questions are selected - select all
            setSelectedQuestions(questions.map(question => question.id));
        } else {
            // All questions are selected - clear selection
            setSelectedQuestions([]);
        }
    };

    // Handle delete answer confirmation
    const handleDeleteAnswer = async () => {
        if (!questionToDelete) return;

        const questionId = questionToDelete.id;
        try {
            const docRef = doc(db, basePath, "contracts", projectName.split('/')[0], "analysis", projectName.split('/').join('_'));
            await runTransaction(db, async (transaction) => {
                const contractDocSnapshot = await transaction.get(docRef);
                if (contractDocSnapshot.exists()) {
                    const data = contractDocSnapshot.data();
                    const updatedSummary = data.summary.filter(q => q.id !== questionId);
                    transaction.update(docRef, { summary: updatedSummary });
                }
            });

            // Update local state
            setRowData(prevRowData => prevRowData.filter(row => row.id !== questionId));
            setErrorMessage("Answer deleted successfully.");
            setShowErrorDialog(true);
        } catch (error) {
            console.error("Error deleting answer:", error);
            setErrorMessage("Failed to delete answer.");
            setShowErrorDialog(true);
        } finally {
            setDeleteAnswerModalVisible(false);
            setQuestionToDelete(null);
        }
    };


    // Show confirmation dialog before deleting
    const showDeleteConfirmation = (question) => {
        setQuestionToDelete(question);
        setDeleteAnswerModalVisible(true);
    };

    const closeDeleteConfirmation = () => {
        setDeleteAnswerModalVisible(false);
        setQuestionToDelete(null);
    };

    return (
        <>
            <Modal show={SummaryModalVisible} onHide={closeSummaryModal} className="modalS" scrollable={true} backdrop="static" dialogClassName="modalS-dialog">

                <Modal.Header className="modalHeader" closeButton>
                    <Modal.Title className="modalTitle">
                        Select topics - <span className="modalTitleP">{projectName}</span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="modalBody" >
                    <div className="TopicsTable">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Topic</th> 
                                    
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectedQuestions.length === questions.length}
                                            onChange={handleSelectAllToggle}
                                        />
                                    </th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {questions.map(question => (
                                    <tr key={question.id}>
                                        <td>{question.id}</td>
                                        <td>{rowData.find(row => row.id === question.id) &&
                                            <FaRegCheckCircle
                                                title="Edit current analysis"
                                                onClick={() => showDeleteConfirmation(question)}
                                                className="add-icon1 s" />}
                                        </td>

                                        <td>{question.topic}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedQuestions.includes(question.id)}
                                                onChange={() => toggleQuestionSelection(question.id)}
                                            />
                                        </td>

                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modalFooter">
                    <Button
                        className="cancelButton"
                        variant="dark"
                        onClick={closeSummaryModal}
                    >
                        Close
                    </Button>
                    <Button
                        className="confirmButton"
                        type="submit"
                        disabled={selectedQuestions.length === 0 || !syncExists}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
                dialogClass="modalD-dialog a"
            />
            <ConfirmationDialog
                show={deleteAnswerModalVisible}
                title="Edit analysis"
                message={
                    <>
                        {`Please confirm the removal of the current "${questionToDelete?.topic}" analysis.`}
                    </>
                }
                onConfirm={handleDeleteAnswer}
                onCancel={closeDeleteConfirmation}
                confirmLabel="Delete"
                cancelLabel="Cancel"
            />
        </>
    );
}
export default SummaryQModal;
