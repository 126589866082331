import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './tw.css';
import '../components/css/auth.css';

import tw from '../assets/tw.svg';
import Logout from '../components/service/Logout';
import Auth from '../Auth';

function HeaderTw({ user }) {
    const [showAuth, setShowAuth] = useState(false);

    const isAuthenticated = !!user;

    const handleLoginClick = () => {
        setShowAuth(true);
    };

    const handleAuthClose = () => {
        setShowAuth(false);
    };

    return (
        <header className="Tw-header">
            <Link to="/" title="twintig.ai">
                <img src={tw} alt="twintig" className="Tw-logo" />
            </Link>
            {isAuthenticated ? (
                <Logout user={user} />
            ) : (
                    <div className="dashboard">
                        <Link to="/login" className="TW-login">Log in</Link>
                        {showAuth && <Auth onClose={handleAuthClose} />}
                    </div>
                )}
        </header>
    );
}

export default HeaderTw;

