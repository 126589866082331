import React, { useContext, useState, useEffect } from 'react';
import { db, doc, getDoc } from '../../Firebase';
import { Modal, Button } from 'react-bootstrap';
import { AppContext } from '../../AppContext'

import "../css/Dialog.css";
import "../css/GPTtables.css";
import "../css/radio.css";
import "../css/Tips.css";
import AlertDialog from "./AlertDialog";
import ConfirmationDialog from './ConfirmationDialog';


function UserModal({ UserModalVisible, setUserModalVisible, closeUserModal, userID }) {

    const { basePath, setBasePath, projectName, setProjectName, setUpdateCount } = useContext(AppContext).contextValue;

    const [selectedMember, setSelectedMember] = useState(null); // State to track the selected member ID
    
    const handleCheckboxChange = (memberID) => {
        setProjectName('');
        // If the clicked checkbox corresponds to the already selected member, deselect it
        if (selectedMember === memberID) {
            setSelectedMember(null);
            setBasePath(`users/${userID}`); // Reset base path if none is selected
            setUpdateCount(count => count + 1);
        } else {
            setSelectedMember(memberID); // Select the new member
            setBasePath(`users/${memberID}`); // Set the base path accordingly
            setUpdateCount(count => count + 1);
        }
    };

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [groupInfo, setGroupInfo] = useState(null); // State to hold group information
    const [userInfo, setUserInfo] = useState([]); // State to hold user info

    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    // Fetch the User Information stored in Firebase
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const docRef = doc(db, 'users', userID);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setUserInfo(data);

                    // Fetch group info after user data is loaded
                    if (data.group) {
                        setGroupInfo(data.group);
                    } else {
                        setGroupInfo(null); // No group found
                    }
                } else {
                    console.log("No user data found!");
                }
            } catch (error) {
                console.log("Error fetching user info: ", error);
            }
        };

        fetchUserInfo();
    }, [userID]);

    let formattedDate = '';
    let tokensE = 0, tokensI = 0, tokensO = 0;
    const email = userInfo.email;
    const status = userInfo.status;

    // Handle account creation date formatting
    const timeLog = userInfo.timeLog;
    if (timeLog && timeLog.toDate) {
        const date = timeLog.toDate();
        formattedDate = date.toLocaleString(); // Assign directly to outer scope variable
    }

    // Handle token totals
    const tokensE_Total = userInfo.tokensE_Total;
    const tokensI_Total = userInfo.tokensI_Total;
    const tokensO_Total = userInfo.tokensO_Total;

    tokensE = tokensE_Total || 0;
    tokensI = tokensI_Total || 0;
    tokensO = tokensO_Total || 0;

    const handleSubmit = () => {
        setUserModalVisible(false);
    };

    return (
        <>
            <Modal show={UserModalVisible} onHide={closeUserModal} className="modalE" scrollable={true} backdrop="static" dialogClassName="modalE-dialog">
                <Modal.Header className="modalE-header" closeButton>
                    <Modal.Title className="modalE-title">
                        User Info
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="modalE-body clearfix">
                    <div>
                        <p className="">User e-mail: <b>{email}</b></p>
                        <p className="">Group: <b>{groupInfo ? groupInfo.Name : "N/A"}</b></p> {/* Safe access groupInfo */}
                        {/* Table for Group Members */}
                        <div className="GroupTable">
                            {groupInfo && groupInfo.length > 0 ? (
                                <table>
                                    <thead>

                                    </thead>
                                    <tbody>
                                        {groupInfo.map((memberID, index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{memberID.name}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedMember === memberID.id} // Only check the box if this member is selected
                                                        onChange={() => handleCheckboxChange(memberID.id)} // Handle checkbox change
                                                        />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No members found.</p>
                            )}
                        </div>
                        <p className="">Account Status: <b>{status}</b></p>
                        <p className="">Account creation date: <b>{formattedDate}</b></p>
                        <p className="">Total used tokens: <b>{(tokensE + tokensI + tokensO).toLocaleString()}</b></p>
                        <div style={{ paddingLeft: '20px', fontSize: '0.85', lineHeight: '1' }} >
                            <p className="">- Embedded tokens: <b>{tokensE.toLocaleString()}</b></p>
                            <p className="">- Input tokens: <b>{tokensI.toLocaleString()}</b></p>
                            <p className="">- Output tokens: <b>{tokensO.toLocaleString()}</b></p>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modalE-footer">
                    <Button className="confirmButton" type="submit" onClick={handleSubmit}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>

            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
                dialogClass="modalE-dialog a"
            />
        </>
    );
}

export default UserModal;


