import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../Firebase";
import AlertDialog from "../utils/AlertDialog";


function Reset() {
    const [email, setEmail] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const [showErrorDialog, setShowErrorDialog] = useState(false);
    // Close Alert dialog
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        navigate('/login'); // Navigate to the login page
    };

    const PasswordReset = (email) => {
        sendPasswordReset(email);
        setShowErrorDialog(true);
    }

    useEffect(() => {
        if (loading) return;
        if (user) navigate("dashboard");
    }, [user, loading]);


    return (
        <div className="reset">
            <div className="form_container1">
                <input
                    type="text"
                    className="reset__textBox"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />

                <button
                    className="action_btn"
                    onClick={() => PasswordReset(email)}
                    >
                    Send password reset email
                </button>

                <div className='login__text'>
                    Don't have an account? <Link to="../register">Register</Link> now.
                    <br />
                    Already have an account? <Link to="/login">Login</Link>
                </div>
            </div>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message="Password reset link sent!"
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />
        </div>
    );
}
export default Reset;