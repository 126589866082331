import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";


import { logout } from "../../Firebase";
import ConfirmationDialog from '../utils/ConfirmationDialog';
import UserModal from '../utils/UserModal';

import { FaSignOutAlt, FaUserAlt } from 'react-icons/fa';

import '../css/auth.css';
import '../css/Dialog.css';

function Logout(props) {

    const navigate = useNavigate();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    // User Modal handle
    const [UserModalVisible, setUserModalVisible] = useState(false);
    const closeUserModal = () => {
        setUserModalVisible(false)
    };
    const openUserModal = () => {
        setUserModalVisible(true)
    };


    const handleLogout = () => {
        setShowConfirmDialog(true);
    };

    const handleConfirmLogout = () => {
        navigate('/');
        logout();
        setShowConfirmDialog(false);
    };

    const handleCancelLogout = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="dashboard">
            <div className="dashboard__text">
                <button className="user__btn" onClick={openUserModal}>
                    <FaUserAlt className="exit-icon" title={props.user.email} />
                </button>
                <button className="exit__btn" onClick={handleLogout}>
                    <FaSignOutAlt className="exit-icon" title="logout"/>
                </button>
            </div>
            <ConfirmationDialog
                show={showConfirmDialog}
                title="Logout"
                message="Are you sure you want to log out?"
                onConfirm={handleConfirmLogout}
                onCancel={handleCancelLogout}
                confirmLabel="Confirm"
                cancelLabel="Cancel"
            />
            {/* Contract analysis Modal */}
            <UserModal
                UserModalVisible={UserModalVisible}
                setUserModalVisible={setUserModalVisible}
                closeUserModal={closeUserModal}
                userID={props.user.uid}
            />
        </div>
    );
}

export default Logout;


